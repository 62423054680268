import * as master from '../types/master';
import * as baseService from './BaseService';


export default class AssignmentsService extends baseService.BaseService {
    private url = '/api/Assignments';

    constructor() {
        super();
    }

    async get(pagedDataOptions: baseService.DataOptions): Promise<baseService.PagedData<master.Assignment>> {
        let response = (await this.api.get(this.url, {
            params:
                { Page: pagedDataOptions.Page, ItemsPerPage: pagedDataOptions.ItemsPerPage, Search: pagedDataOptions.Search, SortBy: pagedDataOptions.SortBy, SortDesc: pagedDataOptions.SortDesc }
        })).data as baseService.PagedData<master.Assignment>;
        return response;
    }
}   