import * as master from '../types/master';
import * as baseService from './BaseService';


export default class CustomerPrescriptionsService extends baseService.BaseService {
    private url = '/api/CustomerPrescriptions';

    constructor() {
        super();
    }

    async getPrescriptionDetails(customerId: number, prescriptionId: number): Promise<master.CustomerPrescriptionDetails> {
        let url = this.url + `/${customerId}/${prescriptionId}`;
        let response;
        response = await this.api.get(url).catch(err => {
            throw err;
        });
        return response.data;
    }

    async getPrescriptionByPatientId(customerId: number, patientId: number): Promise<master.CustomerPrescriptionDetails> {
        let url = this.url + `/patient/${customerId}/${patientId}`;
        let response;
        response = await this.api.get(url).catch(err => {
            throw err;
        });
        return response.data;
    }

    async getPrescriptionByLegacyNumber(customerId: number, legacyNumber: string): Promise<Array<master.CustomerPrescriptionDetails>> {
        let url = this.url + `/getPrescriptionByLegacyNumber/${customerId}/${legacyNumber}`;
        let response;
        response = await this.api.get(url).catch(err => {
            throw err;
        });
        return response.data;
    }
    
    async voidCustomerPrescription(command: master.CustomerVoidOrder) {
        let url = this.url + '/VoidPrescription';
        let response = await this.api.put(url, command).catch(err => {
            throw err;
        });
        return response;
    }

    async unVoidCustomerPrescription(command: master.CustomerVoidOrder) {
        let url = this.url + '/UnVoidPrescription';
        let response = await this.api.put(url, command).catch(err => {
            throw err;
        });
        return response;
    }

    async deleteCustomerPrescription(command: master.CustomerPrescription) {
        let url = this.url + '/DeletePrescription';
        let response = await this.api.put(url, command).catch(err => {
            throw err;
        });
        return response;
    }

    async editCustomerPrescription(command: master.CustomerPrescriptionDetails) {
        let url = this.url + '/Update';
        let response = await this.api.put(url, command).catch(err => {
            throw err;
        });
        return response;
    }

    async patientExistsPrescription(customerId: number, patientFirstName: string, patientLastName: string, dateOfBirth: string, healthPlanMemberId: string, medicalRecordNumber: string): Promise<boolean> {
        let url = this.url + `/getPatientExists/${customerId}/${patientFirstName}/${patientLastName}/${dateOfBirth}/${healthPlanMemberId}/${medicalRecordNumber}`;
        let response = await this.api.get(url).catch(err => {
            throw err;
        });
        return response.data;
    }
}