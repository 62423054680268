<template>
    <v-main>
      <AssignmentsList/>
    </v-main>
</template>

<script>
import AssignmentsList from '../components/Assignment/AssignmentsList.vue';

export default ({

  components: { 
    AssignmentsList,
  },

  data: () => ({      
  }),
});
</script>