<template v-slot:top>
    <v-container>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col md="4"> Assignments </v-col>
                    <v-col offset-md="6" md="2">
                        <v-spacer></v-spacer>
                        <v-text-field v-model="search"
                                      append-icon="mdi-magnify"
                                      label="Search"
                                      single-line
                                      hide-details
                                      clearable
                                      @click:clear="searchCleared">
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-card-title>
            <!--Add Dialog-->
            <v-row>
                <v-dialog v-model="dialogAssignment" v-if="dialogAssignment"
                          max-width="750px"
                          @click:outside="closeAssignmentAddDialog">
                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click="closeAssignmentAddDialog">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>
                            Add Assignment
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn dark text @click="closeAssignmentAddDialog">Close</v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card>
                        <v-card-text>
                            <v-container>
                                <form>
                                    <v-row>
                                        <v-col cols="12">
                                            <assignmentPatientSearch v-on:patient-selected="getSelectedPatient" />
                                        </v-col>
                                    </v-row>
                                    <v-row v-show="patientSelected" class="pl-3">
                                        <v-col cols="12" class=" pa-1">
                                            <div class="text-h6">{{ selectedPatientValues.firstName }} {{ selectedPatientValues.lastName }}</div>
                                        </v-col>
                                        <v-col cols="4" class="pa-1">
                                            <div>Designation: <strong>{{ selectedPatientValues.designation }}</strong></div>
                                        </v-col>
                                        <v-col cols="8" class="pa-1">
                                            <div>Customer: <strong>{{ selectedPatientValues.customerName }}</strong></div>
                                        </v-col>
                                    </v-row>
                                    <v-row v-show="existingAssignmentsNotice">
                                        <v-col cols="12">
                                            <div class="text-h6">This patient already has existing assignments, please use edit instead.</div>
                                        </v-col>
                                    </v-row>
                                    <v-container v-show="patientNoExistingAssignments">
                                        <v-row>
                                            <v-col cols="12">
                                                <v-text-field v-model="selectedPatientValues.deviceSerialNumber"
                                                              v-mask= maskSerialNumber
                                                              :counter="15"
                                                              :maxlength="15"
                                                              :rules="$data.serialNumberRules"
                                                              outlined
                                                              label="Device Serial Number"
                                                              required>
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-menu ref="assignedDateMenu"
                                                        v-model="assignedDateMenu"
                                                        :close-on-content-click="false"
                                                        :return-value.sync="selectedPatientValues.assigned"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field v-model="assignedDateFormatted"
                                                                      label="Assigned Date"
                                                                      prepend-icon="mdi-calendar"
                                                                      v-bind="attrs"
                                                                      v-on="on">
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="selectedPatientValues.assigned"
                                                                   no-title
                                                                   scrollable
                                                                   @input="$refs.assignedDateMenu.save(selectedPatientValues.assigned)">
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-menu ref="shippingDateMenu"
                                                        v-model="shippingDateMenu"
                                                        :close-on-content-click="false"
                                                        :return-value.sync="selectedPatientValues.shippingDate"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field v-model="shippedDateFormatted"
                                                                      label="Shipped Date"
                                                                      prepend-icon="mdi-calendar"
                                                                      v-bind="attrs"
                                                                      v-on="on">
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="selectedPatientValues.shippingDate"
                                                                   no-title
                                                                   scrollable
                                                                   @input="$refs.shippingDateMenu.save(selectedPatientValues.shippingDate)">
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-menu ref="expectedDateMenu"
                                                        v-model="expectedDateMenu"
                                                        :close-on-content-click="false"
                                                        :return-value.sync="selectedPatientValues.expectedDelivery"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field v-model="expectedDateFormatted"
                                                                      label="Expected Delivery Date"
                                                                      prepend-icon="mdi-calendar"
                                                                      v-bind="attrs"
                                                                      v-on="on">
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="selectedPatientValues.expectedDelivery"
                                                                   no-title
                                                                   scrollable
                                                                   @input="$refs.expectedDateMenu.save(selectedPatientValues.expectedDelivery)">
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-menu ref="deliveredDateMenu"
                                                        v-model="deliveredDateMenu"
                                                        :close-on-content-click="false"
                                                        :return-value.sync="selectedPatientValues.delivered"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field v-model="deliveredDateFormatted"
                                                                      label="Delivered Date"
                                                                      prepend-icon="mdi-calendar"
                                                                      v-bind="attrs"
                                                                      v-on="on">
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="selectedPatientValues.delivered"
                                                                   no-title
                                                                   scrollable
                                                                   @input="$refs.deliveredDateMenu.save(selectedPatientValues.delivered)">
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-text-field v-model="selectedPatientValues.trackingNumber"
                                                              label="Tracking Number"
                                                              outlined
                                                              required>
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-textarea v-model="selectedPatientValues.assignmentNote"
                                                            dense
                                                            outlined>
                                                    <template v-slot:label>
                                                        <div>
                                                            Assignment Note <small>(optional)</small>
                                                        </div>
                                                    </template>
                                                </v-textarea>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-textarea v-model="selectedPatientValues.shippingNote"
                                                            dense
                                                            outlined>
                                                    <template v-slot:label>
                                                        <div>
                                                            Shipping Notes <small>(optional)</small>
                                                        </div>
                                                    </template>
                                                </v-textarea>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </form>
                                <v-row class="table-danger pa-3" v-if="errors.length > 0">
                                    <ul v-for="error in errors" :key="error">
                                        <li>{{error}}</li>
                                    </ul>
                                </v-row>
                                <v-row>
                                    <v-card-actions>
                                        <v-btn v-show="patientNoExistingAssignments"
                                               color="primary"
                                               dark
                                               :disabled="loading"
                                               @click="checkNewAssignmentForm">
                                            Assign
                                        </v-btn>
                                        <v-btn color="primary"
                                               dark
                                               text
                                               @click="closeAssignmentAddDialog">
                                            Close
                                        </v-btn>
                                    </v-card-actions>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
                <v-btn v-show="showAddButton"
                               color="secondary"
                               dark
                               class="ml-6"
                               @click="dialogAssignment=true">
                            Add
                </v-btn>
                <v-btn v-show="showBulkEditButton"
                       v-if="isSuperviser"
                       color="info"
                       class="ml-3"
                       @click="showBulkEditSelectionCheckbox">
                    Bulk Edit
                </v-btn>

                <v-btn v-show="showSaveBulkEditButton"
                       color="info"
                       dark
                       class="ml-1"
                       @click="editBulkSelection">
                    Edit Selected
                    <v-badge color="light"
                             :content="bulkEditSelectedPatients.length">
                    </v-badge>
                </v-btn>
                <v-icon color="secondary" large class="mr-2" v-show="showCancelBulkEditButton" @click="cancelBulkEdit">
                    mdi-close-circle
                </v-icon>
            </v-row>
            <!--Bulk Edit Dialog-->
            <v-row>
                <v-dialog v-model="dialogBulkEdit" v-if="dialogBulkEdit"
                          max-width="800px"
                          @click:outside="closeSaveBulkEditSelectionDialog">
                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click="closeSaveBulkEditSelectionDialog">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>
                            Bulk Edit Assignments
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn dark text @click="closeSaveBulkEditSelectionDialog">Close</v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card>
                        <v-card-text>
                            <v-container>
                                <form>
                                    <v-row>
                                        <v-col class="ml-4" cols="12">
                                            Your are bulk editing for <strong>{{ bulkEditSelectedPatients.length }}</strong> patients.
                                            <v-icon medium class="mr-2" @click="viewBulkEditPatients">
                                                mdi-open-in-new
                                            </v-icon>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-select v-model="bulkEditValues.deviceStatusId"
                                                      label="Assignment Event"
                                                      :items="bulkAssignmentEventItems"
                                                      outlined>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-select v-model="bulkEditValues.reason"
                                                      label="Withdrawal Reason"
                                                      :items="assignmentReasonItems"
                                                      outlined
                                                      @change="withdrawnReasonNoteValidation">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row v-show="withdrawalReasonRequiresNote">
                                        <p class="table-success ml-2 pa-2">The withdrawal reason you selected requires an assignment note. </p>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-textarea v-model="bulkEditValues.assignmentNote"
                                                        dense
                                                        outlined>
                                                <template v-slot:label>
                                                    <div>
                                                        Assignment Note <small>(optional)</small>
                                                    </div>
                                                </template>
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                </form>
                                <v-row class="table-danger pa-3" v-if="errors.length > 0">
                                    <ul v-for="error in errors" :key="error">
                                        <li>{{error}}</li>
                                    </ul>
                                </v-row>
                                <v-row>
                                    <v-card-actions>
                                        <v-btn color="primary"
                                               dark
                                               @click="checkBulkEditForm">
                                            Save Bulk Edits
                                        </v-btn>
                                        <v-btn color="primary"
                                               dark
                                               text
                                               @click="closeSaveBulkEditSelectionDialog">
                                            Close
                                        </v-btn>
                                    </v-card-actions>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-row>
            <!--bulk edit selected patients dialog-->
            <v-row>
                <v-dialog v-model="dialogBulkEditSelectedPatients" v-if="dialogBulkEditSelectedPatients"
                          max-width="750px"
                          @click:outside="closeBulkEditSelectedPatientsDialog">
                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click="closeBulkEditSelectedPatientsDialog">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>
                            Patients Selected for Bulk Edit
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn dark text @click="closeBulkEditSelectedPatientsDialog">Close</v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card>
                        <v-card-text>
                            <v-container>
                                <v-data-table :headers="bulkEditSelectedPatientsHeaders"
                                              :items="bulkEditSelectedPatients"
                                              :loading="loading"
                                              :hide-default-footer="true"
                                              class="elevation-1">
                                    <template #item.bulk_full_name="{ item }">
                                        {{ item.patientFirstName }} {{ item.patientLastName }}
                                    </template>
                                    <template #item.remove_patient="{ item }">
                                        <v-btn small color="error" dark @click="removeElement(item)">Remove</v-btn>
                                    </template>
                                </v-data-table>
                                <v-row>
                                    <v-card-actions>
                                        <v-btn color="primary"
                                               dark
                                               @click="closeBulkEditSelectedPatientsDialog">
                                            Close
                                        </v-btn>
                                    </v-card-actions>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-row>
            <!--patient assignment history dialog-->
            <v-dialog content-class="dialog-patient-assignment-history" v-model="dialogPatientAssignmentHistory" v-if="dialogPatientAssignmentHistory"
                          fullscreen
                          hide-overlay
                          transition="dialog-bottom-transition">
                          <v-toolbar dark color="primary">
                                <v-btn icon dark @click="closeAssignmentHistoryDialog">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-toolbar-title>
                                    Assignment History
                                </v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-toolbar-items>
                                    <v-btn dark text @click="closeAssignmentHistoryDialog">Close</v-btn>
                                </v-toolbar-items>
                            </v-toolbar>
                            <v-container offset-md="1" md="10">
                                <v-row class="pl-3">
                                    <v-col cols="12" class=" pa-1">
                                        <div class="text-h6">{{ patientAssignmentHistoryDialogValues.patientFirstName }} {{ patientAssignmentHistoryDialogValues.patientLastName }}</div>
                                    </v-col>
                                </v-row>
                                <AssignmentHistory :patientId="patientAssignmentHistoryDialogValues.patientId" :customerId="patientAssignmentHistoryDialogValues.customerId"/>

                                <v-btn color="primary"
                                        dark
                                        @click="closeAssignmentHistoryDialog">
                                    Close
                                </v-btn>
                            </v-container>
                </v-dialog>
            <v-data-table :headers="computedHeaders"
                          :items="assignments"
                          :server-items-length="totalAssignments"
                          :options.sync="options"
                          :loading="loading"
                          :search="search"
                          :footer-props="{'items-per-page-options': rowsPerPage}">
                <template #item.deviceStatusChangedHeader_formatted="{ item }">
                    {{ item.deviceStatusChanged | formatDate }}
                </template>
                <template #item.full_name="{ item }">
                    <input v-show="bulkEditSelected" :value="item" type="checkbox" v-model="bulkEditSelectedPatients" />
                    {{ item.patientFirstName }} {{ item.patientLastName }}
                    <v-icon medium class="mr-2" @click="viewPatient(item)">
                        mdi-open-in-new
                    </v-icon>
                </template>
                <template #item.patient_status="{ item }">
                    {{ item.deviceStatusDescription }}
                    <v-icon medium class="mr-2" @click="viewPatientStatusHistory(item)">
                        mdi-open-in-new
                    </v-icon>
                </template>
                <template v-slot:top>
                    <!--edit patient assignment dialog-->
                    <v-dialog v-model="dialogAssignmentEdit" v-if="dialogAssignmentEdit"
                              max-width="800px"
                              @click:outside="closeAssignmentEditDialog">
                        <v-toolbar dark color="primary">
                            <v-btn icon dark @click="closeAssignmentEditDialog">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-toolbar-title>
                                Edit Assignment
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                                <v-btn dark text @click="closeAssignmentEditDialog"> Close</v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <v-card>
                            <v-card-text>
                                <v-container>
                                    <form>
                                        <v-row class="pl-3">
                                            <v-col cols="12" class=" pa-1">
                                                <div class="text-h6">{{ assignmentEditDetails.patientFirstName }} {{ assignmentEditDetails.patientLastName }}</div>
                                            </v-col>
                                            <v-col cols="4" class="pa-1">
                                                <div>Designation: <strong>{{ assignmentEditDetails.patientDesignation }}</strong></div>
                                            </v-col>
                                            <v-col cols="8" class="pa-1">
                                                <div>Customer: <strong>{{ assignmentEditDetails.customerName }}</strong></div>
                                            </v-col>
                                            <v-col cols="12" class="pa-1 mb-4">
                                                <div>Legacy ID: <strong>{{ assignmentEditDetails.legacyId }}</strong></div>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="4">
                                                <v-select v-show="!editDeviceSerialNumberCheckbox"
                                                          v-model="assignmentEditDetails.deviceStatusId"
                                                          label="Assignment Event"
                                                          :items="assignmentEventItems"
                                                          outlined
                                                          @change="checkEventType">
                                                </v-select>
                                            </v-col>
                                            <v-col cols="4" v-show="!editDeviceSerialNumberCheckbox && !showReplacedEvent">
                                                Device Serial #: <strong>{{ assignmentEditDetails.deviceSerialNumber }}</strong>
                                            </v-col>
                                            <v-col cols="4" v-show="editDeviceSerialNumberCheckbox">
                                                <v-text-field v-model="assignmentEditDetails.deviceSerialNumber"
                                                              v-mask= maskSerialNumber                                                          
                                                              :counter="15"
                                                              :maxlength="15"
                                                              :rules="$data.serialNumberRules"
                                                              outlined
                                                              label="Edited Serial Number"
                                                              required>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="4" v-show="showReplacedEvent">
                                                <v-text-field v-model="replacementDeviceSerialNumber"
                                                              v-mask= maskSerialNumber
                                                              :counter="15"
                                                              :maxlength="15"
                                                              :rules="$data.serialNumberRules"
                                                              outlined
                                                              label="Replacement Device Serial Number"
                                                              required>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="4" v-show="showEditSerialNumberCheckbox">
                                                <v-checkbox @change="editDeviceSerialNumber"
                                                            v-model="editDeviceSerialNumberCheckbox"
                                                            label="Edit Device Serial #">
                                                </v-checkbox>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6" v-show="showAssignmentEvent">
                                                <v-menu ref="assignedMenu"
                                                        v-model="assignedMenu"
                                                        :close-on-content-click="false"
                                                        :return-value.sync="assignmentEditDetails.assigned"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field v-model="editAssignmentDateFormatted"
                                                                      label="Assigned Date"
                                                                      prepend-icon="mdi-calendar"
                                                                      v-bind="attrs"
                                                                      v-on="on">
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="assignmentEditDetails.assigned"
                                                                   no-title
                                                                   scrollable
                                                                   @input="$refs.assignedMenu.save(assignmentEditDetails.assigned)">
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6" v-show="showShippedEvent">
                                                <v-menu ref="shippingDateMenu"
                                                        v-model="shippingDateMenu"
                                                        :close-on-content-click="false"
                                                        :return-value.sync="assignmentEditDetails.shippingDate"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field v-model="editShippingDateFormatted"
                                                                      label="Shipped Date"
                                                                      prepend-icon="mdi-calendar"
                                                                      v-bind="attrs"
                                                                      v-on="on">
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="assignmentEditDetails.shippingDate"
                                                                   no-title
                                                                   scrollable
                                                                   @input="$refs.shippingDateMenu.save(assignmentEditDetails.shippingDate)">
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col cols="6" v-show="showShippedEvent">
                                                <v-menu ref="expectedDateMenu"
                                                        v-model="expectedDateMenu"
                                                        :close-on-content-click="false"
                                                        :return-value.sync="assignmentEditDetails.expectedDelivery"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field v-model="editExpectedDateFormatted"
                                                                      label="Expected Delivery Date"
                                                                      prepend-icon="mdi-calendar"
                                                                      v-bind="attrs"
                                                                      v-on="on">
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="assignmentEditDetails.expectedDelivery"
                                                                   no-title
                                                                   scrollable
                                                                   @input="$refs.expectedDateMenu.save(assignmentEditDetails.expectedDelivery)">
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6" v-show="showDeliveredEvent">
                                                <v-menu ref="deliveredDateMenu"
                                                        v-model="deliveredDateMenu"
                                                        :close-on-content-click="false"
                                                        :return-value.sync="assignmentEditDetails.delivered"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field v-model="editDeliveredDateFormatted"
                                                                      label="Delivered Date"
                                                                      prepend-icon="mdi-calendar"
                                                                      v-bind="attrs"
                                                                      v-on="on">
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="assignmentEditDetails.delivered"
                                                                   no-title
                                                                   scrollable
                                                                   @input="$refs.deliveredDateMenu.save(assignmentEditDetails.delivered)">
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                        <v-row v-show="showShippedEvent">
                                            <v-col cols="12">
                                                <v-text-field v-model="assignmentEditDetails.trackingNumber"
                                                              label="Tracking Number"
                                                              outlined>
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row v-show="showWithdrawnEvent">
                                            <v-col cols="12">
                                                <v-select v-model="assignmentEditDetails.reason"
                                                          label="Reason"
                                                          :items="assignmentReasonItems"
                                                          outlined
                                                          @change="withdrawnReasonNoteValidation">
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row v-show="withdrawalReasonRequiresNote">
                                            <p class="table-success ml-2 pa-2">The withdrawal reason you selected requires an assignment note. </p>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-textarea v-model="assignmentEditDetails.assignmentNote"
                                                            dense
                                                            outlined>
                                                    <template v-slot:label>
                                                        <div>
                                                            Assignment Note <small>(optional)</small>
                                                        </div>
                                                    </template>
                                                </v-textarea>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" v-show="showShippedEvent">
                                                <v-textarea v-model="assignmentEditDetails.shippingNote"
                                                            dense
                                                            outlined>
                                                    <template v-slot:label>
                                                        <div>
                                                            Shipping Notes <small>(optional)</small>
                                                        </div>
                                                    </template>
                                                </v-textarea>
                                            </v-col>
                                        </v-row>
                                    </form>
                                    <v-row class="table-danger pa-3" v-if="errors.length > 0">
                                        <ul v-for="error in errors" :key="error">
                                            <li>{{error}}</li>
                                        </ul>
                                    </v-row>
                                    <v-row>
                                        <v-card-actions>
                                            <v-btn color="primary"
                                                   dark
                                                   :disabled="loading"
                                                   @click="checkEditForm">
                                                Save
                                            </v-btn>
                                            <v-btn color="primary"
                                                   dark
                                                   text
                                                   @click="closeAssignmentEditDialog">
                                                Close
                                            </v-btn>
                                        </v-card-actions>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                    <!-- patient dialog -->
                    <v-dialog v-model="dialogPatient" v-if="dialogPatient"
                              fullscreen
                              hide-overlay
                              transition="dialog-bottom-transition"
                              persistent :retain-focus="false">
                        <v-card>
                            <div style="background: #123559" v-if="false">
                                <v-btn icon dark @click="dialogPatient = false">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-btn class="float-right" dark text @click="dialogPatient = false"> Close</v-btn>
                            </div>
                            <v-toolbar dark color="primary" v-if="false">
                                <v-btn icon dark @click="closePatientDialog">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-tooltip bottom open-delay="500">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-toolbar-title class="pl-8" @click="copyToClipboard(patientName)" v-on="on" v-bind="attrs">
                                            {{patientName}}
                                        </v-toolbar-title>
                                    </template>
                                    <span>Click to copy to clipboard</span>
                                </v-tooltip>
                                <v-tooltip bottom open-delay="500">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-toolbar-title class="pl-8 blue-grey--text text--lighten-4"
                                                         @click="copyToClipboard(patientDesignation)"
                                                         v-on="on"
                                                         v-bind="attrs">
                                            {{patientDesignation}}
                                        </v-toolbar-title>
                                    </template>
                                    <span>Click to copy to clipboard</span>
                                </v-tooltip>
                                <v-toolbar-title class="pl-8 blue-grey--text text--lighten-4">
                                    {{customerName}}
                                </v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-toolbar-items>
                                    <v-btn dark text @click="closePatientDialog"> Close</v-btn>
                                </v-toolbar-items>
                                <v-snackbar v-model="clipboardSnackbar" :timeout="1100" bottom>
                                    Copied "{{clipboardText}}" to clipboard

                                    <template v-slot:action="{ attrs }">
                                        <v-btn color="blue" text v-bind="attrs" @click="clipboardSnackbar = false">
                                            Close
                                        </v-btn>
                                    </template>
                                </v-snackbar>
                            </v-toolbar>
                            <PatientDetails :patientId="patientDialogValues.patientId"
                                            :customerId="patientDialogValues.customerId"
                                            :customer-name="patientDialogValues.customerName"
                                            :key="componentKey"
                                            v-on:closed="closePatientDialog" />
                        </v-card>
                    </v-dialog>
                </template>
                <template #[`item.actions`]="{ item }">
                    <v-icon medium class="mr-2" @click="editItem(item)" v-if="isSuperviser">
                        mdi-pencil
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>
        <v-snackbar v-model="submitSnackbar" :timeout="12000" bottom>
            {{ submitSnackbarText }}
            <v-btn color="blue"
                   align="right"
                   justify="end"
                   class="float-right"
                   @click="submitSnackbar = false">
                Close
            </v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
    import Vue from "vue";
    import moment from 'moment';
    import AssignmentService from "@/services/AssignmentService";
    import CustomerAssignmentsService from "@/services/CustomerAssignmentService";
    import AssignmentPatientSearch from "./AssignmentPatientSearch.vue";
    import CustomerPurchaseOrdersService from "@/services/CustomerPurchaseOrdersService";
    import CustomerPrescriptionsService from "@/services/CustomerPrescriptionsService";
    import PatientService from "@/services/PatientService";
    import PatientDeviceService from "@/services/PatientDeviceService";
    import PatientDetails from "@/components/PatientDetails.vue";
    import AssignmentHistory from "@/components/Assignment/AssignmentHistory.vue";
    import { contactDetailsUtils } from "@/mixins/contactDetailsUtils";

    Vue.filter('formatDate', function (value) {
        if (value) {
            return moment(String(value)).format('MM/DD/YYYY')
        }
    });

    export default Vue.extend({
        name: "Assignments",
        mixins: [contactDetailsUtils],
        components: {
            AssignmentPatientSearch,
            PatientDetails,
            AssignmentHistory
        },
        data: () => ({
            loading: true,
            sortDesc: false,
            options: {},
            search: "",
            totalAssignments: 0,
            dialogAssignmentEdit: false,
            dialogAssignment: false,
            dialogPatient: false,
            dialogBulkEdit: false,
            dialogBulkEditSelectedPatients: false,
            dialogPatientAssignmentHistory: false,
            withdrawalReasonRequiresNote: false,
            submitSnackbar: false,
            submitSnackbarText: "",
            itemsPerPage: 10,
            displaySupervisor: false,
            errors: [],
            headers: [
                { text: "Date", value: "deviceStatusChangedHeader_formatted", sortable: true, show: true },
                {
                    text: "Patient Name",
                    align: "start",
                    value: "full_name",
                    show: true
                },
                { text: "Patient Designation", value: "patientDesignation", show: true },
                { text: "Customer", value: "customerName", show: true },
                { text: "Serial Number", value: "deviceSerialNumber", show: true },
                { text: "Status", value: "patient_status", show: true },
                { text: "Actions", value: "actions", sortable: false, show: false },
            ],
            bulkEditSelectedPatientsHeaders: [
                {
                    text: "Patient Name",
                    align: "start",
                    value: "bulk_full_name",
                    sortable: false
                },
                { text: "Serial Number", value: "deviceSerialNumber", sortable: false },
                { text: "Current Status", value: "deviceStatusDescription", sortable: false },
                { text: "", value: "remove_patient", sortable: false },
            ],
            assignedMenu: false,
            shippingDateMenu: false,
            expectedDateMenu: false,
            deliveredDateMenu: false,
            assignedDateMenu: false,
            patientSelected: false,
            clipboardSnackbar: false,
            bulkEditSelected: false,
            showAssignmentEvent: false,
            showShippedEvent: false,
            showDeliveredEvent: false,
            showReplacedEvent: false,
            showEditSerialNumberCheckbox: true,
            showWithdrawnEvent: false,
            editDeviceSerialNumberCheckbox: false,
            showSaveBulkEditButton: false,
            showCancelBulkEditButton: false,
            patientNoExistingAssignments: true,
            showBulkEditButton: true,
            showAddButton: true,
            existingAssignmentsNotice: false,
            componentKey: 0,
            replacementDeviceSerialNumber: "",
            clipboardText: "",
            assignments: [],
            patientAssignmentHistory: [],
            bulkEditSelectedPatients: [],
            editedIndex: -1,
            assignmentEventItems: [
                { text: 'Assigned', value: 2 },
                { text: 'Shipped', value: 3 },
                { text: 'Delivered', value: 4 },
                { text: 'Replaced', value: 7 },
                { text: 'Withdrawn', value: 9 },
                { text: 'Return Received', value: 10 },
                { text: 'Un-Withdrew', value: 11 },
                { text: 'Renewal', value: 12 },
            ],
            bulkAssignmentEventItems: [
                { text: 'Withdrawn', value: 9 },
            ],
           assignmentReasonItems: [
              "Amputation",
              "Contact Information Incorrect/Unavailable",
              "Deceased",
              "Discontinued - Loss of Coverage",
              "Discontinued - Not Renewed",
              "Disengaged",
              "Dissatisfied with Service",
              "End of Contract",
              "Fulfillment Issue",
              "Inconvenient (no space/time)",
              "Internal System Issue",
              "Non Foot-related Illness/Contraindication",
              "Open Wound",
              "Perceived Lack of Value",
              "Privacy Concerns/Spam Suspected",
              "Provider Recommendation",
              "Safety Concerns",
              "Tech Issue-Inadequate Cell Service",
              "Tech Issue-Other/Unsatisfied with Resolution",
              "Untrained"
           ],
            selectedPatientValues: { deviceSerialNumber: "" },
            bulkEditValues: {
                deviceStatusId: 9,
            },
            patientDialogValues: {
                patientId: Number,
                customerId: Number,
                patientName: String,
                customerName: String,
                patientDesignation: String
            },
            patientAssignmentHistoryDialogValues: {
                patientId: null,
                patientFirstName: null,
                patientLastName: null,
                customerId: null,
                patientDesignation: null,
            },
            assignmentEditDetails: { deviceSerialNumber: "" },
        }),

        computed: {
            isSuperviser() {
                let role = this.$store.state.achillesUser.userRoles.find((r) =>
                    r.role.name.includes("ClinicalOperations") || r.role.name.includes("DeviceOperations")
                );
                if (role) {
                    return true;
                } else {
                    return false;
                }
            },

            rowsPerPage() {
                return this.$rowsPerPage;
            },

            editAssignmentDateFormatted() {
                if (this.assignmentEditDetails.assigned == null) {
                    return '';
                }
                let isoDateString = moment(this.assignmentEditDetails.assigned).toISOString();
                return new Intl.DateTimeFormat('en-US', {
                    month: '2-digit', day: '2-digit', year: 'numeric'
                }).format(new Date(isoDateString));
            },

            editShippingDateFormatted() {
                if (this.assignmentEditDetails.shippingDate == null) {
                    return '';
                }
                let isoDateString = moment(this.assignmentEditDetails.shippingDate).toISOString();
                return new Intl.DateTimeFormat('en-US', {
                    month: '2-digit', day: '2-digit', year: 'numeric'
                }).format(new Date(isoDateString));
            },

            editExpectedDateFormatted() {
                if (this.assignmentEditDetails.expectedDelivery == null) {
                    return '';
                }
                let isoDateString = moment(this.assignmentEditDetails.expectedDelivery).toISOString();
                return new Intl.DateTimeFormat('en-US', {
                    month: '2-digit', day: '2-digit', year: 'numeric'
                }).format(new Date(isoDateString));
            },

            editDeliveredDateFormatted() {
                if (this.assignmentEditDetails.delivered == null) {
                    return '';
                }
                let isoDateString = moment(this.assignmentEditDetails.delivered).toISOString();
                return new Intl.DateTimeFormat('en-US', {
                    month: '2-digit', day: '2-digit', year: 'numeric'
                }).format(new Date(isoDateString));
            },

            assignedDateFormatted() {
                if (this.selectedPatientValues.assigned == null) {
                    return '';
                }
                let isoDateString = moment(this.selectedPatientValues.assigned).toISOString();
                return new Intl.DateTimeFormat('en-US', {
                    month: '2-digit', day: '2-digit', year: 'numeric'
                }).format(new Date(isoDateString));
            },

            shippedDateFormatted() {
                if (this.selectedPatientValues.shippingDate == null) {
                    return '';
                }
                let isoDateString = moment(this.selectedPatientValues.shippingDate).toISOString();
                return new Intl.DateTimeFormat('en-US', {
                    month: '2-digit', day: '2-digit', year: 'numeric'
                }).format(new Date(isoDateString));
            },

            expectedDateFormatted() {
                if (this.selectedPatientValues.expectedDelivery == null) {
                    return '';
                }
                let isoDateString = moment(this.selectedPatientValues.expectedDelivery).toISOString();
                return new Intl.DateTimeFormat('en-US', {
                    month: '2-digit', day: '2-digit', year: 'numeric'
                }).format(new Date(isoDateString));
            },

            deliveredDateFormatted() {
                if (this.selectedPatientValues.delivered == null) {
                    return '';
                }
                let isoDateString = moment(this.selectedPatientValues.delivered).toISOString();
                return new Intl.DateTimeFormat('en-US', {
                    month: '2-digit', day: '2-digit', year: 'numeric'
                }).format(new Date(isoDateString));
            },

            computedHeaders() {
                let supervisor = this.isSuperviser;

                if (supervisor) {
                    return this.headers;
                }

                return this.headers.filter(x => x.show);
            },
        },

        watch: {
            options: {
                handler() {
                    this.getAssignments();
                },
                deep: true,
            },
            search() {
                this.options.page = 1;
                this.fetchEntriesDebounced();
            },
        },

        methods: {
            getAssignments() {
                this.loading = true;
                let pagedOptions = {
                    Search: this.search,
                    SortBy: this.options.sortBy != null ? this.options.sortBy[0] : null,
                    SortDesc: this.options.sortDesc != null ? this.options.sortDesc[0] : null,
                    ItemsPerPage: this.options.itemsPerPage,
                    Page: this.options.page
                };

                new AssignmentService().get(pagedOptions)
                    .then((value) => {
                        this.assignments = value.items;
                        this.assignments = value.items;
                        this.totalAssignments = value.totalCount;
                        this.loading = false;
                    });
            },

            editDeviceSerialNumber(isChecked) {
                if (isChecked) {
                    this.showAssignmentEvent = false;
                    this.showShippedEvent = false;
                    this.showDeliveredEvent = false;
                    this.showReplacedEvent = false;
                    this.showWithdrawnEvent = false;
                    this.withdrawalReasonRequiresNote = false;
                    this.editDeviceSerialNumberCheckbox = true;
                }
                else {
                    this.checkEventType(this.assignmentEditDetails.deviceStatusId);
                }
            },

            checkEventType(selection) {
                this.showAssignmentEvent = false;
                this.showShippedEvent = false;
                this.showDeliveredEvent = false;
                this.showReplacedEvent = false;
                this.showWithdrawnEvent = false;
                this.showEditSerialNumberCheckbox = true;
                this.withdrawalReasonRequiresNote = false;
                this.editDeviceSerialNumberCheckbox = false;

                // Assigned
                if (selection == 2) {
                    this.showAssignmentEvent = true;
                }
                // Shipped
                if (selection == 3) {
                    this.showShippedEvent = true;
                }
                // Delivered
                if (selection == 4) {
                    this.showDeliveredEvent = true;
                }
                // Replaced
                if (selection == 7) {
                    this.showReplacedEvent = true;
                    this.showEditSerialNumberCheckbox = false;
                }
                // Withdrawn is reason 9
                if (selection == 9) {
                    this.showWithdrawnEvent = true;
                    this.withdrawnReasonNoteValidation();
                }
            },

            checkEditForm(e) {
                this.errors = [];

                if (this.withdrawalReasonRequiresNote && !this.assignmentEditDetails.assignmentNote) {
                    this.errors.push('Assignment note required for withdrawal reason');
                }
                if (this.assignmentEditDetails.deviceSerialNumber.length != 15 && this.assignmentEditDetails.deviceSerialNumber.replace("-", "").length != 9) {
                    this.errors.push('Device serial number length too short');
                }
                // check for shipped date with assignment event
                if (this.assignmentEditDetails.deviceStatusId == 2 && !this.assignmentEditDetails.assigned) {
                    this.errors.push('Assignement events require a assigned date');
                }
                // check for shipped date with assignment event
                if (this.assignmentEditDetails.deviceStatusId == 3 && !this.assignmentEditDetails.trackingNumber) {
                    this.errors.push('Shipped events require a tracking number');
                }
                // check for shipped date with shipping assignment
                if (this.assignmentEditDetails.deviceStatusId == 2 && !this.assignmentEditDetails.deviceSerialNumber) {
                    this.errors.push('Serial number required for a assignment selection');
                }
                // check for shipped date with shipping assignment
                if (this.assignmentEditDetails.deviceStatusId == 7 && !this.assignmentEditDetails.deviceSerialNumber) {
                    this.errors.push('Serial number required for a replacement selection');
                }
                if (this.assignmentEditDetails.deviceStatusId == 7 && this.replacementDeviceSerialNumber.length != 15 && this.replacementDeviceSerialNumber.replace("-", "").length != 9) {
                    this.errors.push('Device serial number length too short');
                }
                // check for shipped date with shipping assignment
                if (this.assignmentEditDetails.deviceStatusId == 3 && !this.assignmentEditDetails.shippingDate) {
                    this.errors.push('Shipped assignments require a shipping date selection');
                }
                // check for delivered date with delivered assignment
                if (this.assignmentEditDetails.deviceStatusId == 4 && !this.assignmentEditDetails.delivered) {
                    this.errors.push('Delivered assignments require a delivered date selection');
                }

                if (!this.errors.length) {
                    this.submitEdit();
                }

                e.preventDefault();
            },

            checkBulkEditForm(e) {
                this.errors = [];

                if (this.withdrawalReasonRequiresNote && !this.bulkEditValues.assignmentNote) {
                    this.errors.push('Assignment note required for withdrawal reason');
                }
                if (!this.bulkEditValues.reason) {
                    this.errors.push('Withdrawal reason is required');
                }

                if (!this.errors.length) {
                    this.saveBulkEditSelection();
                }

                e.preventDefault();
            },

            checkNewAssignmentForm(e) {
                this.errors = [];

                if (!this.selectedPatientValues.deviceSerialNumber) {
                    this.errors.push('Device serial number is required');
                }

                if (!this.errors.length) {
                    this.submit();
                }

                e.preventDefault();
            },

            withdrawnReasonNoteValidation(reason) {
                if (reason == "Fulfillment Issue" || reason == "Health Limitations/Contradinication/Illness"
                    || reason == "Safety Concerns" || reason == "Voluntary (No Perceived Benefit)"
                    || reason == "Voluntary (Other)" || reason == "Voluntary (Too Burdensome)"
                    || reason == "Other") {
                    this.withdrawalReasonRequiresNote = true;
                } else {
                    this.withdrawalReasonRequiresNote = false;
                }
            },

            copyToClipboard(value) {
                let self = this;
                this.$copyText(value).then(
                    function (e) {
                        self.clipboardText = value;
                        self.clipboardSnackbar = true;
                        console.log(e);
                    },
                    function (e) {
                        console.log(e);
                    }
                );
            },

            forceRerender() {
                this.componentKey += 1;
            },

            searchCleared() {
                this.fetchEntriesDebounced();
            },

            fetchEntriesDebounced() {
                // cancel pending call
                clearTimeout(this._timerId);

                // delay new call 500ms
                this._timerId = setTimeout(() => {
                    this.getAssignments();
                }, 500);
            },

            editItem(item) {
                this.assignmentEditDetails = Object.assign({}, item);
                this.checkEventType(item.deviceStatusId);
                this.dialogAssignmentEdit = true;
            },

            closeAssignmentEditDialog() {
                this.assignmentEditDetails = {};
                this.dialogAssignmentEdit = false;
                this.withdrawalReasonRequiresNote = false;
                this.editDeviceSerialNumberCheckbox = false;
                this.replacementDeviceSerialNumber = "";
                this.getAssignments();
            },

            closeAssignmentHistoryDialog() {
                this.dialogPatientAssignmentHistory = false;
                this.patientAssignmentHistoryDialogValues = {};
            },

            showBulkEditSelectionCheckbox() {
                this.bulkEditSelected = true;
                this.showBulkEditButton = false;
                this.showAddButton = false;
                this.showSaveBulkEditButton = true;
                this.showCancelBulkEditButton = true;
            },

            closeSaveBulkEditSelectionDialog() {
                this.bulkEditSelected = true;
                this.withdrawalReasonRequiresNote = false;
                this.dialogBulkEdit = false
                this.showBulkEditButton = false;
                this.showAddButton = false;
                this.showSaveBulkEditButton = true;
                this.showCancelBulkEditButton = true;
            },

            cancelBulkEdit() {
                this.bulkEditSelected = false;
                this.showBulkEditButton = true;
                this.showAddButton = true;
                this.showSaveBulkEditButton = false;
                this.showCancelBulkEditButton = false;
                this.bulkEditSelectedPatients = [];
            },

            closeAssignmentAddDialog() {
                this.dialogAssignment = false;
                this.patientSelected = false;
                this.patientNoExistingAssignments = true;
                this.existingAssignmentsNotice = false;
                this.selectedPatientValues = { deviceSerialNumber: "" };
                this.getAssignments();
            },

            closePatientDialog() {
                this.dialogPatient = false;
                this.getAssignments();
            },

            closeBulkEditSelectedPatientsDialog() {
                this.dialogBulkEditSelectedPatients = false;
            },

            editBulkSelection() {
                this.dialogBulkEdit = true;
            },

            viewBulkEditPatients() {
                this.dialogBulkEditSelectedPatients = true;
            },

            removeElement(item) {
                const selectedIndex = this.bulkEditSelectedPatients.indexOf(item);
                this.bulkEditSelectedPatients.splice(selectedIndex, 1);
            },

            async saveBulkEditSelection() {
                try {
                    this.loading = true;
                    let text = "Are you sure you want to edit " + this.bulkEditSelectedPatients.length + " patients?"
                    if (confirm(text) == true) {
                        await this.bulkEditAssignmentEvent();
                        this.dialogBulkEdit = false;
                        this.cancelBulkEdit();
                        this.bulkEditValues = {
                            deviceStatusId: 9,
                        };
                        this.submitSnackbarText = "Edited Successfully";
                        this.submitSnackbar = true;
                        this.getAssignments();
                    }
                } catch (e) {
                    this.submitSnackbarText = "Something went wrong. Please check your inputs and try again.";
                    this.submitSnackbar = true;
                }

                this.loading = false;
            },

            viewPatient(item) {
                this.patientDialogValues.patientId = item.patientId;
                this.patientDialogValues.customerId = item.customerId;
                this.patientDialogValues.patientName = item.full_name;
                this.patientDialogValues.customerName = item.customerName;
                this.patientDialogValues.patientDesignation = item.patientDesignation;
                this.lookupPatient(item, true);
            },

            viewPatientStatusHistory(item) {
                this.patientAssignmentHistoryDialogValues.patientId = item.patientId;
                this.patientAssignmentHistoryDialogValues.customerId = item.customerId;
                this.patientAssignmentHistoryDialogValues.patientFirstName = item.patientFirstName;
                this.patientAssignmentHistoryDialogValues.patientLastName = item.patientLastName;
                this.patientAssignmentHistoryDialogValues.patientDesignation = item.patientDesignation;
                this.dialogPatientAssignmentHistory = true;
            },

            lookupPatient: async function (item, view) {
                this.loading = true;
                await new PatientService()
                    .get(item.customerId, item.patientId)
                    .then((value) => {
                        this.patient = value;
                        if (view) {
                            this.forceRerender();
                            this.dialogPatient = true;
                        }
                    })
                    .finally(() => (this.loading = false));
            },

            checkDeviceSerialNumberInUse(serialNumber) {
                return new Promise((resolve) => {
                    (async () => {
                        try {
                            await new PatientDeviceService()
                                .getPatientDevicBySerialNumber(serialNumber)
                                .then((value) => {
                                    const deviceInUse = value.devices.length !== 0 || value.devices[0] !== undefined;
                                    return resolve(deviceInUse);
                                });
                        } catch (err) {
                            this.submitSnackbarText = "Something went wrong. Please check your inputs and try again.";
                            this.submitSnackbar = true;
                            console.log("Error checking for device serial number: " + err);
                        }
                    })();
                })
            },

            async GetPatientLegacyId() {
                try {
                    if (this.selectedPatientValues.customerId == 1 || this.selectedPatientValues.customerId == 5) {
                        await new CustomerPurchaseOrdersService()
                            .getPurchaseOrderByPatientId(this.selectedPatientValues.customerId, this.selectedPatientValues.patientId)
                            .then((value) => {
                                this.selectedPatientValues.legacyId = value.legacyId;
                                return value.legacyId;
                            });
                    } else {
                        await new CustomerPrescriptionsService()
                            .getPrescriptionByPatientId(this.selectedPatientValues.customerId, this.selectedPatientValues.patientId)
                            .then((value) => {
                                this.selectedPatientValues.legacyId = value.legacyId;
                                return value.legacyId;
                            });
                    }

                } catch (err) {
                    console.log("Error retrieving legacyId : " + err);
                }
            },

            async submit() {
                this.loading = true;
                try {
                    await this.saveNewDeviceEvent();
                    this.closeAssignmentAddDialog();
                    this.submitSnackbarText = "Added Successfully";
                    this.submitSnackbar = true;
                } catch (e) {
                    this.submitSnackbarText = "Something went wrong. Please check your inputs and try again.";
                    this.submitSnackbar = true;
                }
                this.getAssignments();
                this.loading = false;
            },

            async saveEditDeviceEvent() {
                if (this.assignmentEditDetails.deviceStatusId == 7) {
                    this.assignmentEditDetails.deviceSerialNumber = this.replacementDeviceSerialNumber;
                }
                this.assignmentEditDetails.updatedByUserId = this.$store.state.achillesUser.userId;
                await new CustomerAssignmentsService()
                    .saveEditDeviceEvent(this.assignmentEditDetails)
                    .catch((err) => {
                        console.log(err);
                    });
            },

            async submitEdit() {
                this.loading = true;
                try {
                    // DeviceStatusId 7 == Replaced
                    if (this.assignmentEditDetails.deviceStatusId == 7) {
                        if (await this.checkDeviceSerialNumberInUse(this.replacementDeviceSerialNumber)) {
                            this.submitSnackbarText = "A device with this serial number is already in use.";
                            this.submitSnackbar = true;
                            this.replacementDeviceSerialNumber = "";
                            return false;
                        }
                    }
                    if (this.editDeviceSerialNumberCheckbox) {
                        if (await this.checkDeviceSerialNumberInUse(this.assignmentEditDetails.deviceSerialNumber)) {
                            this.submitSnackbarText = "A device with this serial number is already in use.";
                            this.submitSnackbar = true;
                            return false;
                        }
                        await this.updatePatientSerialNumber();
                        this.submitSnackbarText = "Device Serial Number Updated";
                        this.submitSnackbar = true;
                    } else {
                        await this.saveEditDeviceEvent();
                        this.submitSnackbarText = "Edited Successfully";
                        this.submitSnackbar = true;
                    }
                } catch (e) {
                    this.submitSnackbarText = "Something went wrong. Please check your inputs and try again.";
                    this.submitSnackbar = true;
                }
                this.loading = false;
                this.closeAssignmentEditDialog();
                this.getAssignments();
            },

            async updatePatientSerialNumber() {
                await new CustomerAssignmentsService()
                    .updateDeviceSerialNumber(this.assignmentEditDetails)
                    .catch((err) => {
                        console.log(err);
                    });

                this.editDeviceSerialNumberCheckbox = false;
            },

            async bulkEditAssignmentEvent() {
                this.bulkEditValues.updatedByUserId = this.$store.state.achillesUser.userId;
                let that = this;
                this.bulkEditSelectedPatients.forEach(async function (patient) {
                    that.bulkEditValues.patientId = patient.patientId;
                    that.bulkEditValues.customerId = patient.customerId;
                    that.bulkEditValues.legacyUniqueId = patient.legacyUniqueId;
                    that.bulkEditValues.patientDesignation = patient.patientDesignation;
                    that.bulkEditValues.deviceSerialNumber = patient.deviceSerialNumber;

                    // Write to PatientDeviceEvent table
                    await new CustomerAssignmentsService()
                        .saveEditDeviceEvent(that.bulkEditValues)
                        .catch((err) => {
                            console.log(err);
                        });
                });
            },

            async saveNewDeviceEvent() {
                this.selectedPatientValues.updatedByUserId = this.$store.state.achillesUser.userId;
                await new CustomerAssignmentsService()
                    .saveNewDeviceEvent(this.selectedPatientValues)
                    .catch((err) => {
                        console.log(err);
                    });
            },

            checkForExistingAssignments: function (customerId, patientId) {
                return new Promise((resolve) => {
                    (async () => {
                        try {
                            await new CustomerAssignmentsService()
                                .getPatientAssignmentHistory(customerId, patientId)
                                .then((value) => {
                                    if (value.assignments.length > 0) {
                                        return resolve(true);
                                    }
                                    else {
                                        return resolve(false);
                                    }
                                })
                        } catch (err) {
                            console.log(err);
                        }
                    })();
                })
            },

            async getSelectedPatient(patientValue) {
                const existingAssignments = await this.checkForExistingAssignments(patientValue.customerId, patientValue.patientId)

                if (existingAssignments) {
                    this.patientNoExistingAssignments = false;
                    this.existingAssignmentsNotice = true;
                }
                this.patientSelected = true;
                this.selectedPatientValues = patientValue;
                await this.GetPatientLegacyId();
            },
        },
    });
</script>
<style>
    .table-success {
        background-color: #e1ffe3;
    }

    .table-danger {
        background-color: #f5c6cb;
    }

    .dialog-patient-assignment-history {
        background-color: #ffffff;
    }
</style>